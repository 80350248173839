"use client";
import Link from "next/link";
import { InstagramLogo } from "../base/Footer";
import Logo from "../base/Logo";
import Text from "../text";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from "../ui/dialog";
import Image from "next/image";
import { useEffect, useState } from "react";

export default function HomePopup() {
  const [open, setOpen] = useState<boolean>(false);
  useEffect(() => {
    const unix = JSON.parse(localStorage.getItem("popup/home") || "null");
    const open = !unix
      ? true
      : new Date().getTime() > unix + 6 * 60 * 60 * 1000;

    if (open) {
      const timeout = setTimeout(() => {
        setOpen(true);
        localStorage.setItem(
          "popup/home",
          JSON.stringify(new Date().getTime())
        );
        window.addEventListener("unload", (_) => {
          localStorage.removeItem("popup/home");
        });
      });
      return () => clearTimeout(timeout);
    }
  }, []);
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="max-w-4xl max-h-[95svh] bg-background-accent overflow-y-auto">
        <DialogTitle />
        <DialogDescription />
        <div className="flex flex-col gap-8">
          <div className="flex flex-col items-center gap-4 max-w-2xl mx-auto">
            <Logo className="h-14 md:h-24" />
            <Text
              variant="bodySm"
              fontWeight="semibold"
              className="text-center"
              tone="subdued"
            >
              {
                "Our Funeral Services Specialize In Christian, Buddhist, Catholic, Taoist, Direct Cremation Service, 1 Stop Solution To Any Funeral Need. We Are Able To Provide Funeral Setup At Multiple Location Including HDB Void Decks, Funeral Parlours, Landed Houses, Open Spaces And Churches."
              }
            </Text>
            <div className="flex items-center justify-center md:justify-between gap-2 md:gap-8 w-full flex-wrap">
              <Link
                aria-label="Send us a message on WhatsApp"
                target="_blank"
                href={
                  "https://wa.me/+6587509882?text=" +
                  "Hello Heavens Gate, I would like to enquire on your funeral services"
                }
              >
                <Text as="span" variant="body" fontWeight="bold">
                  24 Hour Careline
                </Text>
                <Text
                  as="span"
                  tone="primary"
                  className="font-body text-xl sm:text-3xl tracking-[0px] font-bold ml-2"
                >
                  8750 9882
                </Text>
              </Link>

              <Link
                aria-label="Connect with us on Instagram"
                href="https://www.instagram.com/heavensgatefuneralsg"
                target="_blank"
                className="flex items-center gap-2"
              >
                <InstagramLogo className="size-4" />
                <Text fontWeight="bold">Heavensgatefuneralsg</Text>
              </Link>
            </div>
          </div>

          <div className="grid grid-cols-2 md:grid-cols-3 gap-2 md:gap-4">
            {[
              "/shared/homepopup/1.webp",
              "/shared/homepopup/2.webp",
              "/shared/homepopup/3.webp",
              "/shared/homepopup/4.webp",
              "/shared/homepopup/5.webp",
              "/shared/homepopup/6.webp",
            ].map((image, idx) => (
              <div key={idx}>
                <Image
                  src={image}
                  width={512}
                  height={512}
                  className="w-full h-full object-cover aspect-[16/9]"
                  sizes="256px"
                  alt={""}
                />
              </div>
            ))}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
