import { SVGProps } from "react";
import logo from "@/../public/logo.webp";
import Image from "next/image";
import { cn } from "@/utilities/cn";

export default function Logo({className}: {className?: string}) {
  return (
   <Image src={logo} alt="" className={cn("object-contain h-10 w-auto", className)} priority sizes="128px"/>
  );
}
