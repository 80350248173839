import { IAppRoute } from "../types/app-route";

export const appRoutes: IAppRoute[] = [
  {
    name: "Home",
    path: "/",
    arialLabel: "Go to home page",
  },
  {
    name: "About",
    path: "/about",
    arialLabel: "Go to about page",
  },
  {
    name: "Services",
    path: "/services",
    arialLabel: "Go to helpers page",
  },
  {
    name: "Blog",
    path: "/blog",
    arialLabel: "Go to blog",
  },
  {
    name: "Contact Us",
    path: "/contact",
    arialLabel: "Go to contact page",
    hidden: true,
  },
];
