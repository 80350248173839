import Link from "next/link";
import Logo from "./Logo";
import Text from "../text";
import { appRoutes } from "@/data/app-routes";
import { SVGProps } from "react";

export default function Footer() {
  return (
    <div>
      <footer className="bg-background-accent">
        <div className="container flex flex-col py-8 md:py-16">
          <Logo className="h-16 md:h-24 mb-16 md:mb-24 mx-auto" />

          <div className="flex items-center flex-wrap justify-center w-full md:gap-x-4 border-b border-[#141733] uppercase tracking-[0.15rem] pb-4 md:pb-0 mb-8">
            {appRoutes.map((route, idx) => (
              <Link
                key={idx}
                aria-label={route.arialLabel}
                href={route.path}
                className="px-4 py-2 whitespace-nowrap"
              >
                {route.name}
              </Link>
            ))}
          </div>

          <div className="flex items-center gap-4 justify-center">
            <Link
              aria-label="Connect with us on Facebook"
              href="https://www.facebook.com/profile.php?id=61565027678942"
              target="_blank"
            >
              <FacebookLogo />
            </Link>
            <Link
              aria-label="Connect with us on Instagram"
              href="https://www.instagram.com/heavensgatefuneralsg"
              target="_blank"
            >
              <InstagramLogo />
            </Link>
          </div>
        </div>
      </footer>
      <div className="flex items-center justify-center p-4">
        <Text as="span" variant="body">
          © {new Date().getFullYear()} Heaven’s Gate Funeral. All Rights
          Reserved.
        </Text>
      </div>
    </div>
  );
}

export function FacebookLogo() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 12.8614C22 7.3048 17.5229 2.80029 12 2.80029C6.47715 2.80029 2 7.3048 2 12.8614C2 17.8831 5.65684 22.0455 10.4375 22.8003V15.7697H7.89844V12.8614H10.4375V10.6448C10.4375 8.12325 11.9305 6.73041 14.2146 6.73041C15.3088 6.73041 16.4531 6.92692 16.4531 6.92692V9.4029H15.1922C13.95 9.4029 13.5625 10.1785 13.5625 10.9742V12.8614H16.3359L15.8926 15.7697H13.5625V22.8003C18.3432 22.0455 22 17.8833 22 12.8614Z"
        fill="black"
      />
    </svg>
  );
}
export function InstagramLogo(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 0.800293H5C2.23858 0.800293 0 3.03887 0 5.80029V13.8003C0 16.5617 2.23858 18.8003 5 18.8003H13C15.7614 18.8003 18 16.5617 18 13.8003V5.80029C18 3.03887 15.7614 0.800293 13 0.800293ZM16.25 13.8003C16.2445 15.5929 14.7926 17.0448 13 17.0503H5C3.20735 17.0448 1.75549 15.5929 1.75 13.8003V5.80029C1.75549 4.00764 3.20735 2.55578 5 2.55029H13C14.7926 2.55578 16.2445 4.00764 16.25 5.80029V13.8003ZM13.75 6.05029C14.3023 6.05029 14.75 5.60257 14.75 5.05029C14.75 4.49801 14.3023 4.05029 13.75 4.05029C13.1977 4.05029 12.75 4.49801 12.75 5.05029C12.75 5.60257 13.1977 6.05029 13.75 6.05029ZM9 5.30029C6.51472 5.30029 4.5 7.31501 4.5 9.80029C4.5 12.2856 6.51472 14.3003 9 14.3003C11.4853 14.3003 13.5 12.2856 13.5 9.80029C13.5027 8.60599 13.0294 7.45986 12.1849 6.61537C11.3404 5.77088 10.1943 5.29763 9 5.30029ZM6.25 9.80029C6.25 11.3191 7.4812 12.5503 9 12.5503C10.5188 12.5503 11.75 11.3191 11.75 9.80029C11.75 8.28149 10.5188 7.05029 9 7.05029C7.4812 7.05029 6.25 8.28149 6.25 9.80029Z"
        fill="black"
      />
    </svg>
  );
}
